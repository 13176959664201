import { defineComponent } from "vue";
export default defineComponent({
  name: "self_title",
  props: {
    title: {
      type: String,
      required: true
    },
    inClass: {
      default: "font-semibold text-4xl mb-4"
    }
  }
});