import { createWebHashHistory, createRouter } from "vue-router";
import { useElMessage } from "@/utilities/useElMessage";
// import { validateToken } from "@/utilities/validateToken";

import Auth from "./auth";
import Member from "./officialWeb/member";
import Paper from "./Paper";
import Admin from "./dashBoard";
import Apply from "./officialWeb/apply";
import PaperCommittee from "./officialWeb/paperCommittee";
import Conference from "@/views/officialWeb/conferenceInfos/conferenceInfo.vue";
import CheckIn from "./officialWeb/checkIn";

import Index from "@/views/homePage/Index.vue";

// import Contribute from "@/views/Contribute.vue";
import Terms from "@/views/Terms.vue";
import Policy from "@/views/Policy.vue";
import VueCookie from "vue-cookies";

// routes

const routes = [
  ...Auth,
  ...Member,
  ...Paper,
  ...Admin,
  ...Apply,
  ...PaperCommittee,
  ...CheckIn,

  // {
  //   path: "/contribute",
  //   component: Contribute,
  // },
  {
    path: "/",
    component: Index,
    name: "HomePage",
  },

  {
    path: "/terms",
    component: Terms,
    name: "Terms",
  },
  {
    path: "/policy",
    component: Policy,
    name: "Policy",
  },
  {
    path: "/conference",
    component: Conference,
    name: "Conference",
  },
  { path: "/user/Check/ResetPassword" },
  //#region 社群帳號--登入
  {
    path: "/user/SocialAccount/Redirect/Login/Selection",
    beforeEnter() {
      return {
        name: "toBindAccountOrRegister",
      };
    },
  },
  {
    //社群帳號-登入成功
    path: "/user/SocialAccount/Redirect/Login/Success",
    beforeEnter() {
      // alert("Success");
      const redirectToPage = window.$cookies.get("redirectTo") || null;
      useElMessage("success", "社群帳號登入成功");
      if (redirectToPage) {
        return { name: redirectToPage };
      }
      return {
        name: "HomePage",
      };
    },
  },
  {
    path: "/user/SocialAccount/Redirect/Login/Error",
    beforeEnter() {
      // alert("Error");
      const redirectToPage = window.$cookies.get("redirectTo") || null;

      useElMessage("error", "社群帳號登入失敗");
      if (redirectToPage) {
        return { name: redirectToPage };
      }
      return {
        name: "login",
      };
    },
  },
  { path: "/SocialAccount/Redirect2A" },
  //#endregion
  // 社群帳號-綁定
  {
    path: "/user/SocialAccount/Redirect/Bind/Success",
    beforeEnter() {
      useElMessage("success", "社群帳號綁定成功");
      return {
        name: "account",
      };
    },
  },
  {
    path: "/user/SocialAccount/Redirect/Bind/Error",
    beforeEnter() {
      useElMessage("error", "社群帳號綁定失敗");
      return {
        name: "account",
      };
    },
  },
  //#endregion

  //#region 社群帳號--解除綁定
  {
    path: "/user/SocialAccount/Redirect/Unbind/Success",
    beforeEnter() {
      useElMessage("success", "社群帳號解除綁定成功");
      return { name: "account" };
    },
  },
  {
    path: "/user/SocialAccount/Redirect/Unbind/Error",
    beforeEnter() {
      useElMessage("error", "社群帳號解除綁定失敗");
      return { name: "account" };
    },
  },
  //#endregion

  //#region 社群帳號異常
  {
    path: "/user/SocialAccount/Error/Login",
    beforeEnter() {
      useElMessage("error", "您已登入，無須操作!");
      return { path: "/" };
    },
  },
  {
    path: "/user/SocialAccount/Error/LoginTimeOut",
    beforeEnter() {
      useElMessage("error", "操作逾時");
      return { path: "/" };
    },
  },
  {
    path: "/user/SocialAccount/Error/Hand",
    beforeEnter() {
      useElMessage("error", "操作異常");
      const redirectToPage = window.$cookies.get("redirectTo") || null;

      if (redirectToPage) {
        return { name: redirectToPage };
      }
      return { path: "/" };
    },
  },
  {
    path: "/user/SocialAccount/Error",
    beforeEnter() {
      useElMessage("error", "操作異常");
      const redirectToPage = window.$cookies.get("redirectTo") || null;

      if (redirectToPage) {
        return { name: redirectToPage };
      }
      return { path: "/" };
    },
  },
  {
    path: "/user/SocialAccount/Error/Fail",
    beforeEnter() {
      useElMessage("error", "系統發生異常！");
      const redirectToPage = window.$cookies.get("redirectTo") || null;

      if (redirectToPage) {
        return { name: redirectToPage };
      }
      return { path: "/" };
    },
  },
  //#endregion

  //#region 重設密碼
  {
    path: "/user/Check/ResetPassword/:code/:email?",
    beforeEnter: [
      (to) => {
        // console.log("to", to);
        if (Object.keys(to.params).length) {
          if (to.params.email) {
            return {
              name: "signUpOTPEmail",
              params: {
                code: to.params.code,
                email: to.params.email,
              },
            };
          } else {
            return {
              name: "signUpOTPEmail",
              params: {
                mode: "resetPwd",
                code: to.params.code,
              },
            };
          }
        }
      },
    ],
  },

  //#endregion
  // #region 導向驗證信箱驗證碼頁面
  {
    path: "/user/Check/SignUpOTPEmail/:code/:email?",
    beforeEnter: [
      (to) => {
        // console.log("to", to);
        if (Object.keys(to.params).length) {
          if (to.params.email) {
            return {
              name: "signUpOTPEmail",
              params: {
                code: to.params.code,
                email: to.params.email,
              },
            };
          } else {
            return {
              name: "signUpOTPEmail",
              params: {
                code: to.params.code,
              },
            };
          }
        }
      },
    ],
  },
  //#endregion
  // #region 導向驗證信箱驗證碼頁面
  {
    path: "/user/Check/ChangeEmail/:code/:email?",
    beforeEnter: [
      (to) => {
        // console.log("to", to);
        if (Object.keys(to.params).length) {
          if (to.params.email) {
            return {
              name: "signUpOTPEmail",
              params: {
                code: to.params.code,
                email: to.params.email,
                mode: "ChangeEmail",
              },
            };
          } else {
            return {
              name: "signUpOTPEmail",
              params: {
                code: to.params.code,
                mode: "ChangeEmail",
              },
            };
          }
        }
      },
    ],
  },
  //#endregion
  {
    path: "/test/aes",
    name: "aes",
    component: () => import("@/views/test/Aes.vue"),
  },
  {
    path: "/404",
    name: "404",
    component: import("@/views/notFound/404.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      const targetEl = document.querySelector(to.hash);
      // console.log('to.hash');
      return { el: targetEl, top: 100, behavior: "smooth" };
    } else {
      // console.log('else');
      return { top: 0 };
    }
  },
});

router.beforeEach(async () => {});
export default router;
