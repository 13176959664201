// layouts
import CommitteeLayout from "@/layouts/CommitteeLayout.vue";
import { useDashboardFormType } from "@/store/dashboardFormTypeStore";

export default [
  {
    component: CommitteeLayout,
    path: "/committee",
    redirect: "/committee/list",
    meta: {
      breadcrumb: {
        name: "審查管理",
      },
    },
    async beforeEnter(to, from, next) {
      const DashboardFormType = useDashboardFormType();
      await DashboardFormType.getBasicData();
      next();
    },
    children: [
      {
        path: "list",
        component: () =>
          import(
            "@/views/officialWeb/managePaperCommittee/ManagePaperCommitteeList.vue"
          ),
        name: "ManagePaperCommitteeList",
      },
      {
        path: "/committee/:id",
        component: () =>
          import(
            "@/views/officialWeb/managePaperCommittee/ManagePaperCommitteeEdit.vue"
          ),
        name: "ManagePaperCommitteeEdit",
      },
    ],
  },
];
