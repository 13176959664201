import Navbar2 from "@/components/Navbars/IndexNavbar2.vue";
// import FooterSmall from "@/components/Footers/FooterSmall.vue";

import registerBg2 from "@/assets/img/pattern_vue.png";
import FooterComponent from "@/components/Footers/Footer.vue";

// import ToTop from "@/components/ToTopBtn/index.vue";
export default {
  data: function data() {
    return {
      registerBg2: registerBg2
    };
  },
  components: {
    FooterComponent: FooterComponent,
    Navbar2: Navbar2
    // FooterSmall,
    // ToTop,
  }
};