import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-16b63db2"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "relative w-full h-full min-h-screen flex items-center"
};
var _hoisted_2 = {
  class: "absolute bottom-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-cover"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_Navbar2 = _resolveComponent("Navbar2");
  var _component_router_view = _resolveComponent("router-view");
  var _component_footer_component = _resolveComponent("footer-component");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_Navbar2, {
    class: "fixed"
  }), _createElementVNode("main", null, [_createElementVNode("section", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
    class: "bgReverse bg-cover w-5/12 h-1/12 mt-64",
    style: _normalizeStyle("background-image: url('".concat($data.registerBg2, "');"))
  }, null, 4)]), _createVNode(_component_router_view)]), _createVNode(_component_footer_component)])]);
}