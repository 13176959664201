// import zhTw from 'element-plus/lib/locale/lang/zh-tw';
import { useRoute } from "vue-router";
export default {
  setup: function setup() {
    var route = useRoute();
    // let locale = zhTw;
    // return { locale };
    return {
      route: route
    };
  }
};