export default [
  {
    path: "/apply/:step?",
    component: () => import("@/views/apply/index.vue"),
    meta: {
      breadcrumb: {
        name: "報名",
      },
    },
    name: "Apply",
  },
  {
    path: "/apply/enterStatus",
    beforeEnter(to, from) {
      console.log("enterStatus router", to, from);
      if (to.query) {
        return {
          path: "/apply/4",
          query: to.query,
        };
      }
      return {
        path: "/apply/4",
      };
    },
  },
  // {
  //   //繳費結果狀態
  //   path: "/enterStatus",
  //   name: "enterStatus",
  //   component: () => import("@/views/apply/enterStatus"),
  // },
  {
    /*
     * 可能因為隱私問題，tappay不能作為子menu
     *
     * */
    //信用卡繳費
    path: "/paymentCreditCard",
    name: "paymentCreditCard",
    component: () => import("@/views/apply/paymentCreditCard.vue"),
  },
];
