// layouts

import Paper from "@/layouts/Paper.vue";

export default [
    {
        path: "/paper",
        redirect: "/paper/list",
        component: Paper,
        children: [
            {
                path: "/paper/list",
                component: () => import("@/views/Paper/PaperList.vue"),
                name: "PaperList",
            },
            {
                path: "/paper/create",
                component: () => import("@/views/Paper/PaperSubmitAbstract.vue"),
                name: "PaperCreate",
            },
            {
                path: "/paper/update/:to_paper/:action/",
                component: () => import("@/views/Paper/PaperUpdateAbstract.vue"),
                name: "PaperUpdate",
            },
        ],
    },
];