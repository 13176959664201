import IndexNavbar2 from "@/components/Navbars/IndexNavbar2.vue";
import team2 from "@/assets/img/team-2-800x800.jpg";
import FooterComponent from "@/components/Footers/Footer.vue";
export default {
  data: function data() {
    return {
      team2: team2
    };
  },
  components: {
    FooterComponent: FooterComponent,
    IndexNavbar2: IndexNavbar2
  }
};