// layouts

import Auth from "@/layouts/Auth.vue";
import { useElMessage } from "@/utilities/useElMessage";

export default [
  {
    path: "/auth",
    redirect: "/auth/login",
    component: Auth,
    children: [
      {
        //登入
        path: "/auth/login",
        component: () => import("@/views/auth/Login.vue"),
        name: "login",
      },
      {
        //註冊
        path: "/auth/register",
        component: () => import("@/views/auth/Register.vue"),
        name: "register",
      },
      {
        //更改密碼
        path: "/auth/changePwd",
        component: () => import("@/views/auth/changePwd.vue"),
        name: "changePwd",
        props: true,
      },
      {
        //寄送認證email
        path: "/auth/resetPwd",
        component: () => import("@/views/auth/resetPwd_sendEmail.vue"),
        name: "resetPwd",
      },
      {
        //認證email的驗證碼
        path: "/auth/signUpOTPEmail",
        component: () => import("@/views/auth/signUpOTPEmail.vue"),
        name: "signUpOTPEmail",
      },
      {
        //社群帳號轉址
        path: "/SocialAccount/:to_page/:action/:app_name/",
        component: () => import("@/views/auth/SocialAccount.vue"),
        name: "SocialAccount",
      },
      {
        //綁定社群帳號
        path: "/auth/toBindSocialAccount",
        component: () => import("@/views/auth/toBindSocialAccount.vue"),
        name: "toBindSocialAccount",
      },
      {
        path: "/auth/socialAccount/bindAccount",
        component: () => import("@/views/auth/bindSocialAccount/Login.vue"),
        name: "bindAccountLogin",
      },
      {
        //使用社群帳號註冊
        path: "/auth/socialAccount/register",
        component: () => import("@/views/auth/bindSocialAccount/Register.vue"),
        name: "bindAccountRegister",
      },
      {
        //註冊成功後，選擇綁定帳號或是登入帳號
        path: "/auth/toBindAccountOrRegister",
        component: () => import("@/views/auth/toBindAccountOrRegister.vue"),
        name: "toBindAccountOrRegister",
        beforeEnter() {
          // alert("Success");
          const redirectToPage = window.$cookies.get("redirectTo") || null;
          // useElMessage("success", "社群帳號登入成功");
          if (redirectToPage == "checkInInfo") {
            //如果沒有綁定社群帳號進行報到動作,需要導回報到首頁
            useElMessage(
              "error",
              "您可能尚未報名繳費，請現場報名繳費;或者您尚未綁定此社群帳號，請輸入報到者姓名進行報到"
            );
            return { name: "checkInHome" };
          }
          return {
            name: "HomePage",
          };
        },
      },
    ],
  },
];
