import { createApp } from "vue";
import router from "./router";

//清除舊版本的cookies
const allCookies = window.$cookies.keys();
if (allCookies.length > 0) {
  allCookies.forEach((el) => {
    if (!el.includes("_ga")) {
      window.$cookies.remove(el, "", ".tsgrh.org.tw");
    }
  });
}

//全域使用自定義樣式的alert
import { showAlert } from "@/utilities/showAlert";

// mouting point for the whole app

//全域使用element message
import { ElMessage, ElMessageBox } from "element-plus";
import "element-plus/theme-chalk/el-message.css";
import "element-plus/theme-chalk/el-message-box.css";

//註冊所有的element plus icon
import * as ElementPlusIconsVue from "@element-plus/icons-vue";

//vue-cookise
import VueCookie from "vue-cookies";
//預設紀錄8小時(測試/正式)
let cookieDomain = "localhost";
// console.log("process.env.VUE_APP_NODE_ENV", process.env.VUE_APP_NODE_ENV);
if (process.env.VUE_APP_NODE_ENV !== "local") {
  // console.log("cookieDomain", cookieDomain);
  cookieDomain = process.env.VUE_APP_COOKIES_DOMAIN;
  window.$cookies.config("8h", "", cookieDomain);
}

import App from "@/App.vue";

//pinia
import { createPinia } from "pinia";

const app = createApp(App);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

//全域使用‘千分位’
import { numberToCurrencyString as currency } from "@/utilities/numberToCurrencyString";
//參考：https://ithelp.ithome.com.tw/articles/10282483?sc=rss.qu
app.config.globalProperties.$transfer = {
  //轉千分位數
  currency,
};

//設定全域loading
import { showElLoading, hideElLoading } from "@/utilities/useElLoading";
// styles
import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";
import "@/assets/styles/main.css";

//wow
import "animate.css";

import VueSignaturePad from 'vue-signature-pad'
app
  .use(VueSignaturePad)
  .provide("$showLoading", () => showElLoading())
  .provide("$hideLoading", () => hideElLoading)
  .use(createPinia())
  .use(ElMessage)
  .use(ElMessageBox)
  .use(VueCookie)
  .use(router)
  .provide("showAlert", showAlert);

app.mount("#app");
