// layouts

import Admin from "@/layouts/Admin.vue";
import apply from "@/router/dashBoard/apply";
import checkIn from "@/router/dashBoard/checkIn";

import { useDashboardFormType } from "@/store/dashboardFormTypeStore.js";
import { useAuth } from "@/store/authStore";
import { useElMessage } from "@/utilities/useElMessage";
// import { usePermission } from "@/store/permissionStore.js";
// import { useElMessage } from "@/utilities/useElMessage";

export default [
  {
    path: "/dashboard",
    redirect: "/dashboard/homePage",
    component: Admin,
    name: "dashBoard",
    async beforeEnter(to, from, next) {
      const DashboardFormType = useDashboardFormType();
      await DashboardFormType.getBasicData();
      const AuthStore = useAuth();
      await AuthStore.checkLoginStatus()
        .then((res) => {
          // console.log("authstore", res);
          if (res.manager) {
            // console.log("to", to);
            next();
          } else {
            // console.log("to22222", to);

            useElMessage("error", "無法訪問此頁面");
            next({ name: "HomePage" });
          }
        })
        .catch((err) => {
          console.log("err authstore", err);

          useElMessage("error", "無法訪問此頁面");
          next({ name: "HomePage" });
        });
    },

    children: [
      ...apply,
      ...checkIn,
      {
        path: "/dashboard/homePage",
        component: () => import("@/views/dashBoard/dashBoard.vue"),
        name: "dashBoardHome",
      },
      {
        path: "/dashboard/accounts/list",
        component: () => import("@/views/dashBoard/Accounts/index.vue"),
        name: "AccountsList",
        // async beforeEnter(to, from, next) {
        //   const PermissionStore = usePermission();
        //   await PermissionStore.getUserPermission();
        //   console.log("isView", PermissionStore.isView);
        //   if (!PermissionStore.isView) {
        //     useElMessage("error", "無法訪問此頁面");
        //     next({ name: "dashBoardHome" });
        //   }
        //   next();
        // },
      },
      {
        path: "/dashboard/accounts/edit/:id",
        component: () => import("@/views/dashBoard/Accounts/accountEdit.vue"),
        name: "AccountsEdit",
        // async beforeEnter(to, from, next) {
        //   const PermissionStore = usePermission();
        //   if (!PermissionStore.isEdit) {
        //     useElMessage("error", "無法訪問此頁面");
        //     next({ name: "dashBoardHome" });
        //   }
        //   next();
        // },
      },
      {
        path: "/dashboard/paper/list",
        component: () => import("@/views/dashBoard/Paper/ManagePaperList.vue"),
        name: "ManagePaperList",
        // async beforeEnter(to, from, next) {
        //   const PermissionStore = usePermission();
        //   await PermissionStore.getPaperPermission();
        //   if (!PermissionStore.isView) {
        //     useElMessage("error", "無法訪問此頁面");
        //     next({ name: "dashBoardHome" });
        //   }
        //   next();
        // },
      },
      {
        path: "/dashboard/paper/:to_paper/:action/:status",
        component: () => import("@/views/dashBoard/Paper/ManagePaperEdit.vue"),
        name: "ManagePaperEdit",
      },
      {
        path: "/dashboard/paper/committee",
        component: () =>
          import("@/views/dashBoard/Paper/ManagePaperCommittee.vue"),
        name: "ManagePaperCommittee",
      },
      {
        path: "/dashboard/paper/status",
        component: () =>
          import("@/views/dashBoard/Paper/ManagePaperStatus.vue"),
        name: "ManagePaperStatus",
      },
      {
        path: "/dashboard/sponsor",
        component: () => import("@/views/dashBoard/ManageSponsor.vue"),
        name: "ManageSponsor",
      },
      {
        path: "/dashboard/payment",
        component: () => import("@/views/dashBoard/ManagePayment.vue"),
        name: "ManagePayment",
      },
    ],
  },
];
