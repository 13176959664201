// layouts
import Auth from "@/layouts/Auth.vue";

import Account from "@/views/officialWeb/member/account.vue";
import AccountEdit from "@/views/officialWeb/member/accountEdit.vue";

export default [
  // {
  // 	path: '/member',
  // 	redirect: '/member',
  // 	component: Admin,
  // 	children: [
  // 		{
  // 			path: '/account',
  // 			component: Account,
  // 			name: 'account',
  // 		},
  // 	],
  // },
  {
    component: Auth,
    path: "/member",
    redirect: "/account",
    meta: {
      breadcrumb: {
        name: "會員中心",
      },
    },
    children: [
      {
        path: "/account",
        component: Account,
        meta: {
          breadcrumb: {
            name: "個人資料",
          },
        },
        name: "account",
      },
      {
        path: "/accountEdit",
        component: AccountEdit,
        meta: {
          breadcrumb: {
            name: "修改個人資料",
          },
        },
        name: "AccountEdit",
      },
    ],
  },
];
