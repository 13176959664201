import AxiosService from "@/http";

export function validateToken() {
  return AxiosService.get("auth/status/login")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.data;
    });
}
