import { ElLoading } from "element-plus";

let loadingInstance = null;
export const showElLoading = () => {
  // console.log("showElLoading", loadingInstance);
  if (!loadingInstance) {
    loadingInstance = ElLoading.service({ fullscreen: true });
  }
};

export const hideElLoading = () => {
  if (loadingInstance) {
    loadingInstance.close();
    loadingInstance = null;
  }
};
