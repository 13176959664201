import ToTop from "@/components/ToTopBtn/index.vue";
export default {
  components: {
    ToTop: ToTop
  },
  data: function data() {
    return {
      date: new Date().getFullYear()
    };
  },
  mounted: function mounted() {
    var self = this;
    self.$nextTick(function () {
      self.$emit("eventGetHeight", self.$el.offsetHeight);
    });
  }
};