import { ElMessage } from "element-plus";

export const useElMessage = (type, msg) => {
  console.log("useElMessage", msg);
  if (msg == null || msg == "" || msg == undefined) return;
  ElMessage({
    type: type,
    message: msg,
    duration: msg?.length > 20 ? 10000 : 5000,
    grouping: true,
    showClose: true,
  });
};
