import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_CaretTop = _resolveComponent("CaretTop");
  var _component_el_icon = _resolveComponent("el-icon");
  var _component_el_button = _resolveComponent("el-button");
  return _openBlock(), _createBlock(_component_el_button, {
    type: "success",
    circle: "",
    size: "large",
    onClick: $options.scrollToTop
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_el_icon, null, {
        default: _withCtx(function () {
          return [_createVNode(_component_CaretTop)];
        }),
        _: 1
      }), _createTextVNode(" Top ")];
    }),
    _: 1
  }, 8, ["onClick"]);
}