import ToTop from "@/components/ToTopBtn/index.vue";
export default {
  components: {
    ToTop: ToTop
  },
  data: function data() {
    return {
      date: new Date().getFullYear()
    };
  },
  props: {
    absolute: {
      type: Boolean,
      default: false
    }
  }
};