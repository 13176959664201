import axios from "axios";
import router from "@/router";
import { useElMessage } from "@/utilities/useElMessage";

const AxiosService = axios.create({
  baseURL: process.env.VUE_APP_BASEURL,
  timeout: 60 * 1000,
});

AxiosService.interceptors.request.use((config) => {
  config.data = JSON.stringify(config.data);
  config.headers["content-type"] = "application/json";
  config.headers["Authorization"] = `${window.$cookies.get(
    "token_type"
  )} ${window.$cookies.get("token")}`;
  // console.log("config", config);
  return config;
});

AxiosService.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log("error", error);
    /*
     *  api回覆300時，
     *  target：轉址的位置，預設官網首頁
     *  success = true /false
     *  msg (空值就不顯示提示訊息)
     */
    if (error.response.status === 301) {
      const err = error.response.data;
      // console.log("http 301", error.response.data);
      //因為個別呼叫api的時候，catch都有寫useElMessage，所以共用的地方先註解，待討論其他方法判斷是否需要使用useElMessage
      // if (err.msg) {
      //   useElMessage(err.success ? "success" : "error", err.msg);
      // }
      if (err.target.includes("http") || err.target.includes("https")) {
        window.location.href = err.target;
      } else {
        //跳轉到首頁
        window.location.href = `${process.env.VUE_APP_PUBLICPATH}${err.target}`;
        return Promise.reject(error.response);
      }
    }
    /*
     *  api回覆501時，
     *  1。出現錯誤訊息
     */
    if (error.response.status === 501) {
      useElMessage("error", error.response.data.msg);
      //跳轉到首頁
      router.replace({
        name: "HomePage",
      });
      return Promise.reject(error.response);
    }
    /*
     *  api回覆403時，
     *  1。出現錯誤訊息
     *  2。返回後台首頁
     */
    // if (error.response.status === 403) {
    //   // useElMessage("error", `${error.response.data.msg}/11`);
    //   //跳轉到首頁
    //   router.replace({
    //     name: "dashBoard",
    //   });
    //   return Promise.reject(error.response);
    // }

    /*
     *  api回覆401時，
     *  1。跳轉到登入耶
     *  2。登入完成返回當前頁面
     */
    if (error.response.status === 401) {
      // console.log("http status 401", error.response.data.msg);
      //儲存當前頁面的路由
      const currentRoute = router.currentRoute.value;
      // console.log("current route", currentRoute);
      if (currentRoute.fullPath.includes("redirect")) {
        /*
         * 如果path中,已有redirect,直接返回
         * */
        // console.log("redirect", currentRoute.fullPath);
        return Promise.reject(error.response);
      } else if (currentRoute.fullPath.includes("login")) {
        /*
         * 如果path是前往login,直接返回
         * */
        // console.log("login http");
        return Promise.reject(error.response);
      } else {
        //跳轉到登入頁
        router.push({
          name: "login",
          query: { redirect: currentRoute.fullPath },
        });
        //todo 需要換方法清除cookies
        //清除所有session
        for (const [key, value] of Object.entries(sessionStorage)) {
          console.log("key", key, value);
          sessionStorage.removeItem(key);
        }
        //清除所有cookies
        const allCookies = self.$cookies.keys();
        allCookies.forEach((el) => {
          self.$cookies.remove(el, "", process.env.VUE_APP_COOKIES_DOMAIN);
        });
      }
    }
    return Promise.reject(error.response);
  }
);

export default AxiosService;
