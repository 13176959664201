//sex data
export const sexOpts = [
  {
    value: "M",
    label: "男",
  },
  { value: "F", label: "女" },
];

export const applyOpts = [
  { value: "1", label: "申請" },
  { value: "0", label: "不申請" },
];
