import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, unref as _unref, createTextVNode as _createTextVNode } from "vue";
var _hoisted_1 = {
  id: "conferenceSchedule"
};
var _hoisted_2 = {
  class: "pt-20 pb-10 relative"
};
var _hoisted_3 = {
  class: "justify-center text-center flex flex-wrap mt-10"
};
var _hoisted_4 = {
  class: "w-full md:w-8/12 px-1 md:px-4"
};
var _hoisted_5 = {
  key: 0,
  class: "hidden"
};
var _hoisted_6 = {
  key: 1
};
var _hoisted_7 = ["innerHTML"];
var _hoisted_8 = {
  class: "flex flex-col md:flex-row justify-between mt-8"
};
var _hoisted_9 = ["href"];
var _hoisted_10 = ["href"];
import FooterComponent from "@/components/Footers/Footer.vue";
import IndexNavbar2 from "@/components/Navbars/IndexNavbar2.vue";
import Self_title from "@/views/homePage/self_title.vue";
import { ref, reactive } from "vue";
import OrallyTable from "@/views/officialWeb/conferenceInfos/components/OrallyTable.vue";
import PosterTable from "@/views/officialWeb/conferenceInfos/components/PosterTable.vue";
import { Download } from "@element-plus/icons-vue";
export default {
  __name: 'conferenceInfo',
  setup: function setup(__props) {
    /* eslint-disable */
    var orallyFile = ref("".concat(process.env.VUE_APP_FILEROOT, "%E5%8F%A3%E9%A0%AD%E7%99%BC%E8%A1%A8%E8%AD%B0%E7%A8%8B.pdf"));
    var posterFile = ref("".concat(process.env.VUE_APP_FILEROOT, "%E6%B5%B7%E5%A0%B1%E7%B7%A8%E8%99%9F%E5%8F%8A%E7%AB%B6%E8%B3%BD%E8%AA%AA%E6%98%8E.pdf"));
    var table = ref({
      columns: [{
        prop: "time",
        label: "時段"
      }, {
        prop: "title",
        label: "議程"
      }, {
        prop: "note"
      }, {
        prop: "location",
        label: "地點"
      }],
      headerCellStyle: function headerCellStyle(inCol) {
        var row = inCol.row,
          columnIndex = inCol.columnIndex;
        // console.log("header", row, columnIndex);
        row[1].colSpan = 2;
        row[2].colSpan = 0;
        if (columnIndex === 2) {
          return "hidden";
        }
      },
      spanMethod: function spanMethod(inTable) {
        var row = inTable.row,
          column = inTable.column,
          rowIndex = inTable.rowIndex,
          columnIndex = inTable.columnIndex;
        if (columnIndex === 2) {
          if (rowIndex === 9) {
            return {
              rowspan: 4,
              colspan: 1
            };
          } else {
            return {
              rowspan: 0,
              colspan: 1
            };
          }
        } else if (columnIndex === 1) {
          if (rowIndex !== 9 && rowIndex != 10 && rowIndex !== 11 && rowIndex != 12) {
            return {
              rowspan: 1,
              colspan: 2
            };
          }
        } else if (columnIndex === 0) {
          if (rowIndex === 7) {
            return {
              rowspan: 2,
              colspan: 1
            };
          } else if (rowIndex === 8) {
            return {
              rowspan: 1,
              colspan: 0
            };
          }
        }
      },
      datas: [{
        time: "08:30-09:15",
        title: "報到入場",
        location: "國立陽明交通大學賢齊館<br />國際會議廳"
      }, {
        time: "09:15-09:30",
        title: "開幕式貴賓致詞",
        location: "國立陽明交通大學賢齊館<br />國際會議廳"
      }, {
        time: "09:30-09:40",
        title: "地下水貢獻奬頒奬與致詞",
        location: "國立陽明交通大學賢齊館<br />國際會議廳"
      }, {
        time: "09:40-10:30",
        title: "專題演講<br />講者：水利署 黃宏莆副署長<br />題目：台灣地下水資源概況及保育措施",
        location: "國立陽明交通大學賢齊館<br />國際會議廳"
      }, {
        time: "10:30-10:40",
        title: "大合照",
        location: "國立陽明交通大學賢齊館<br />國際會議廳"
      }, {
        time: "10:40-11:00",
        title: "茶敘時間",
        location: "國立陽明交通大學賢齊館<br />交誼廳1"
      }, {
        time: "11:00-11:45",
        title: "專題演講<br />講者：加州大學洛杉磯分校土木工程系 葉文工教授<br />題目：Conjunctive Use of Surface Water and Groundwater in Southern California, USA,<br />and Optimization Methods for Groundwater Modeling",
        location: "國立陽明交通大學賢齊館<br />春耕軒、夏耘軒"
      }, {
        time: "12:00-12:50",
        title: "午餐(休息)",
        location: "國立陽明交通大學賢齊館<br />交誼廳1"
      }, {
        time: "12:00-12:50",
        title: "臺灣地下水資源暨水文地質學會【會員大會】",
        location: "國立陽明交通大學賢齊館<br />國際會議廳"
      }, {
        time: "13:00-14:15",
        title: "口頭發表A<br />子題A /地下水與水文地質特性研討<br />子題B /地下水資源規劃與管理<br />",
        location: "國立陽明交通大學賢齊館<br />春耕軒、夏耘軒",
        note: "海報評選<br />一般論文<br />海報展示"
      }, {
        time: "14:15-14:50",
        title: "臺灣地下水資源暨水文地質學會【理監事會】",
        location: "國立陽明交通大學賢齊館<br />國際會議廳"
      }, {
        time: "14:50-15:00",
        title: "茶敘時間",
        location: "國立陽明交通大學賢齊館<br />交誼廳1"
      }, {
        time: "15:00-16:15",
        title: "口頭發表B<br />子題C /地下水污染與風險評估<br />子題D /新興技術與水文地質調查",
        location: "國立陽明交通大學賢齊館<br />春耕軒、夏耘軒"
      }, {
        time: "16:30-17:00",
        title: "閉幕式",
        location: "國立陽明交通大學賢齊館<br />國際會議廳"
      }, {
        time: "17:00~20:00",
        title: "晚宴/頒獎",
        location: "國立陽明交通大學賢齊館<br />交誼廳1"
      }]
    });
    var orallyAgendaTable = ref([{
      titles: {
        main: "議題Ａ：地下水與水文地質特性研討",
        sub: "場地：春耕軒"
      },
      contents: {
        title: [{
          value: "時段",
          prop: "time"
        }, {
          value: "篇名",
          prop: "name"
        }, {
          value: "作者",
          prop: "auth"
        }],
        content: [{
          rowSpan: 5,
          time: "13：00~14：15",
          name: " Groundwater Governance is a Key for Sustainable Water Conservation and Use",
          auth: "Maki Tsujimura"
        }, {
          name: "123123",
          auth: "555"
        }]
      }
    }]);
    var posterAgendaTable = ref([{
      titles: {
        main: "一般海報編號"
      },
      contents: {
        title: [{
          value: "編號",
          prop: "id"
        }, {
          value: "篇名",
          prop: "name"
        }],
        content: [{
          id: "N-A2-001",
          name: "多時序土壤濕度指標評估區域地表入滲潛勢-以濁水溪沖積扇南扇為例"
        }, {
          id: "N-A2-002",
          name: "應用 ETC Pask 入滲試驗探討濁水溪沖積扇與其周緣重要補注區之地表入滲特性"
        }]
      }
    }]);
    return function (_ctx, _cache) {
      var _component_el_table_column = _resolveComponent("el-table-column");
      var _component_el_table = _resolveComponent("el-table");
      var _component_el_icon = _resolveComponent("el-icon");
      var _component_el_button = _resolveComponent("el-button");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(IndexNavbar2), _createElementVNode("section", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(Self_title, {
        title: "會議議程"
      }), _createVNode(_component_el_table, {
        data: table.value.datas,
        border: "",
        stripe: "",
        "span-method": table.value.spanMethod,
        "header-cell-class-name": table.value.headerCellStyle
      }, {
        default: _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(table.value.columns, function (col) {
            return _openBlock(), _createBlock(_component_el_table_column, {
              prop: col.prop,
              label: col.label,
              align: "center"
            }, {
              header: _withCtx(function (_ref) {
                var column = _ref.column,
                  $index = _ref.$index;
                return [$index === 2 ? (_openBlock(), _createElementBlock("span", _hoisted_5)) : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(column.label), 1))];
              }),
              default: _withCtx(function (_ref2) {
                var row = _ref2.row,
                  $index = _ref2.$index;
                return [_createElementVNode("span", {
                  innerHTML: row[col.prop]
                }, null, 8, _hoisted_7)];
              }),
              _: 2
            }, 1032, ["prop", "label"]);
          }), 256))];
        }),
        _: 1
      }, 8, ["data", "span-method", "header-cell-class-name"]), _createElementVNode("div", _hoisted_8, [_createElementVNode("a", {
        href: orallyFile.value,
        target: "_blank",
        class: "md:mb-4"
      }, [_createVNode(_component_el_button, {
        size: "large",
        type: "success",
        class: "text-2xl p-8"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(" 口頭議程檔案下載 "), _createVNode(_component_el_icon, null, {
            default: _withCtx(function () {
              return [_createVNode(_unref(Download))];
            }),
            _: 1
          })];
        }),
        _: 1
      })], 8, _hoisted_9), _createElementVNode("a", {
        href: posterFile.value,
        target: "_blank"
      }, [_createVNode(_component_el_button, {
        size: "large",
        type: "success",
        class: "text-2xl p-8"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode("海報議程檔案下載 "), _createVNode(_component_el_icon, null, {
            default: _withCtx(function () {
              return [_createVNode(_unref(Download))];
            }),
            _: 1
          })];
        }),
        _: 1
      })], 8, _hoisted_10)])])])]), _createVNode(FooterComponent)]);
    };
  }
};