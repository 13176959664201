import { defineStore } from "pinia";
import AxiosService from "@/http/index";

export const useDashboardFormType = defineStore("DashboardFormType", {
  state: () => ({
    accountGroupOpts: [],
    paper_status: [],
    paper_type: [],
    paper_theme: [],
    review_status: [],
    review_result: [],
    review_publishway: [],
    reviewer: [],
    enterWay: [],
    enterEat: [],
  }),
  actions: {
    async getBasicData() {
      const self = this;
      try {
        const { data: item } = await AxiosService("manage/basic_data");
        item.data.group.forEach((item) => {
          item["label"] = item["name"];
          item["value"] = item["id"];
        });
        item.data.enter_eat.forEach((item) => {
          item["label"] = item["name"];
          item["value"] = item["item_fid"];
        });
        // console.log(item.data.enter_way);
        self.accountGroupOpts = item.data.group;
        self.paper_status = item.data.paper_status;
        self.paper_type = item.data.paper_type;
        self.paper_theme = item.data.paper_theme;
        self.review_status = item.data.review_status;
        self.review_result = item.data.review_result;
        self.review_publishway = item.data.review_publishway;
        self.enterWay = item.data.enter_way;
        self.enterEat = item.data.enter_eat;
      } catch (err) {
        console.error(err);
      }
      /*
      try {
        const { data: item } = await AxiosService("manage/paper_review/reviewer_list");
        // item.data.forEach((item) => {
        //   item["label"] = item["name"];
        //   item["value"] = item["id"];
        // });
        self.reviewer = item.data;
        // console.log(item.data);
      } catch (err) {
        console.error(err);
      }

       */
    },
  },
});
