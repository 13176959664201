// layouts

export default [
  {
    path: "/dashboard/applyList",
    component: () => import("@/views/dashBoard/Apply/ApplyList.vue"),
    name: "ApplyList",
  },
  {
    path: "/dashboard/applyList/:id",
    component: () => import("@/views/dashBoard/Apply/ApplyEdit.vue"),
    name: "ApplyEdit",
  },
];
