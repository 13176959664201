import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-2cca3d3b"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "pt-16"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_CommitteeNavbar = _resolveComponent("CommitteeNavbar");
  var _component_router_view = _resolveComponent("router-view");
  var _component_footer_component = _resolveComponent("footer-component");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_CommitteeNavbar, {
    class: "fixed"
  }), _createElementVNode("main", _hoisted_1, [_createVNode(_component_router_view), _createVNode(_component_footer_component)])]);
}