export default [
  {
    path: "/conference/checkIn",
    component: () => import("@/views/officialWeb/checkIn/checkInHome.vue"),
    name: "checkInHome",
  },
  {
    path: "/conference/checkIn/confirm",
    component: () => import("@/views/officialWeb/checkIn/checkInInfo.vue"),
    name: "checkInInfo",
  },
  {
    path: "/conference/CheckIn/info",
    component: () => import("@/views/officialWeb/checkIn/checkInResult.vue"),
    name: "checkInResult",
  },
];
