import { createApp, h } from 'vue';
import AlertComponent from '@/components/Alerts/AlertsBase.vue';

let appInstance = null;

export const showAlert = (message, type = 'info', duration = 3000) => {
	if (!appInstance) {
		const app = createApp({
			render() {
				return h(AlertComponent, {
					message: this.message,
					type: this.type,
					show: this.show,
				});
			},
			data() {
				return {
					message,
					type,
					show: true,
				};
			},
		});

		const container = document.createElement('div');
		document.body.appendChild(container);
		appInstance = app.mount(container);
	} else {
		appInstance.message = message;
		appInstance.type = type;
		appInstance.show = true;
	}

	if (duration > 0) {
		setTimeout(() => {
			appInstance.show = false;
		}, duration);
	}
};
