//參考：https://blog.51cto.com/wjw1014/5411413
export function numberToCurrencyString(value) {
  if (!value) return 0;
  //獲得整數部分
  const intPart = Math.trunc(value);
  //整數部分處理
  const intPartFormat = intPart
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  //預定義小數部分和整數部分
  let floatPart = "";
  //將數值截取為小數部分和整數部分
  const valueArray = value.toString().split(".");

  if (valueArray.length === 2) {
    //有小數部分
    //取得小數部分
    floatPart = valueArray[1].toString();
    return `${intPartFormat}.${floatPart}`;
  }
  return `${intPartFormat}${floatPart}`;
}
