import { defineStore } from "pinia";
import AxiosService from "@/http";

export const useAuth = defineStore("Auth", {
  state: () => ({}),
  actions: {
    //檢查目前登入狀態
    checkLoginStatus() {
      return new Promise((resolve, reject) => {
        AxiosService.get(`auth/status/login`)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
});
