import { defineStore } from "pinia";
import AxiosService from "@/http";

export const useApply = defineStore("Apply", {
  state: () => ({
    userName: null,
    userEnterWay: null,
    isCertified: null,
    applyFeeDescription: [
      `早鳥優惠報名請於 <span class="font-bold">2023/10/01</span> 23:59:59(UTC+8)前填寫完報名資料並繳交報名費用；\n一般報名請於 <span class="font-bold">2023/10/08</span> 23:59:59(UTC+8)前填寫完報名資料並繳交報名費用。\n 未於一般報名截止時間完成報名繳費者，請於會議當天至現場報名繳費！`,
      `報名繳費費用：\n一般人士（非學會會員）：新臺幣2,000元（早鳥 1,400元）\n一般人士（學會會員）：新臺幣1,500元（早鳥 1,000元）\n學生（非學會會員）：新臺幣1,500元（早鳥 1,000元）\n學生（學會會員）：新臺幣1,300元（早鳥 900元）\n現場報名繳費：新臺幣2,500元`,
      `目前繳費方式提供\n a. 信用卡：支援Visa、MasterCard、JCB\n b. ATM：持綠界金流系統合作銀行發行之金融卡並選擇該銀行，當使用該銀行ATM櫃員機繳費即免跨行手續費；若選擇其他銀行ATM櫃員機每筆交易須支付跨行手續費新臺幣15 元。\nATM合作銀行請參考<a target="_blank" href="https://www.ecpay.com.tw/About/Partnerbank" class="text-blue-400 underline">https://www.ecpay.com.tw/About/Partnerbank</a>。\n c.超商繳費，支援臺灣超商 7-ELEVEN、全家、萊爾富、OK店。\n`,
      "學會會員認證僅限臺灣地下水資源暨水文地質學會個人會員，請於報名頁面輸入姓名及身分證字號，將一併於線上繳交本年度常年會費。若您為學生會員，須於報名身份選項選擇學生。\n",
      "學生身份報名者，須上傳學生證正反面/在學證明/指導教授簽署證明檔案，檔案最多可上傳兩個檔案，限 5MB 以內（支援檔案格式：JPG / JPEG / PNG ）。\n",
      "贊助單位報名者，須於報名身份選擇贊助單位並輸入認證碼。\n",
      "線上報名繳費之收據將於當日會議給予，現場報名繳費之收據將於會議結束後以郵件寄送方式給予。\n",
      "報名繳費實際開始截止時間以重要時程公告頁面為準!\n",
    ],
  }),
  actions: {
    getInitPage: (inPage) => {
      AxiosService.get("enter/init", { params: { page: inPage } }).then();
    },
  },
});
