import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  id: "app"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_router_view = _resolveComponent("router-view");
  var _component_el_config_provider = _resolveComponent("el-config-provider");
  return _openBlock(), _createBlock(_component_el_config_provider, null, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [(_openBlock(), _createBlock(_component_router_view, {
        key: $setup.route.fullPath
      }))])];
    }),
    _: 1
  });
}