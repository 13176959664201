import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "relative md:ml-48 bg-blueGray-100"
};
var _hoisted_2 = {
  class: "px-4 md:px-10 mx-auto w-full"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_sidebar = _resolveComponent("sidebar");
  var _component_router_view = _resolveComponent("router-view");
  var _component_header_stats = _resolveComponent("header-stats");
  var _component_footer_admin = _resolveComponent("footer-admin");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_sidebar), _createElementVNode("div", _hoisted_1, [_createVNode(_component_header_stats, null, {
    default: _withCtx(function () {
      return [_createVNode(_component_router_view)];
    }),
    _: 1
  }), _createElementVNode("div", _hoisted_2, [_createVNode(_component_footer_admin)])])]);
}