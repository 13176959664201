export default {
  name: 'emerald-alert',
  data: function data() {
    return {
      alertOpen: true
    };
  },
  methods: {
    closeAlert: function closeAlert() {
      this.alertOpen = false;
    }
  }
};